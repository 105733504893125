<template>
    <div class="section pt20 upper-category__top">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 col-xl-8 col-container">
                    <div class="card-columns" v-if="mainFilter">
                        <div class="custom-category-card card" v-for="(category, index) in mainFilter.options" :key="index">
                            <extended-kategorie-card :mainCategory="mainFilter" :subCategoryId="mainFilter.field_type_id" :item="category"/>
                        </div>  
                    </div>
                </div>
                <div class="col-12 col-xl-4 people-container"><img class="people" src="/assets/people.svg">
                </div>
            </div>

        </div>
    </div>
</div>


</template>

<script>
    import { getCtype } from '@/api/contentType';
    import { getMainCategoryIcon } from '@/utils/lupe-helpers';
    import { showLoader, hideLoader, getStructuredFilters, getBasicFilters } from '@/utils/helpers';

    export default {
        name: 'Category',
        components: {
            ExtendedKategorieCard: () => import('@/components/cards/ExtendedKategorie.vue'), 
        },
        data() {
            return {
                contentType: null,
            }
        },
        created(){
            this.getOfferSettings();
        },
        computed: {
            structuredFilters(){
             return getStructuredFilters(this.contentType);
         },
         mainFilter(){
            if(this.structuredFilters!==null && this.structuredFilters.length>0){
                return this.structuredFilters[0];
            }
        },
        mainCategory(){
            if(this.categoryFilters!==null){
                return {
                    id: this.categoryId,
                    value : this.categoryFilters.label
                }
            }
            return null;
        },
    },
    methods: {
        getCtype,
        showLoader, 
        hideLoader,
        getMainCategoryIcon,
        getOfferSettings(){
            this.getContentType();
        },
        getContentType() {
            this.contentType = null;
            var id = this.$offerId;
            this.loader = this.showLoader(this.loader);
        /*overwrite the existing filters*/
            this.selectedFilters = new Map();
            getCtype(id,this.$store.getters.getLanguage).then(response => {
                this.contentType = response.data;
            })
            .finally(() => {
                this.loader = this.hideLoader(this.loader);
            });
        },
    }
}
</script>
<style lang="scss" scoped>
    @import '@/scss/_variables.scss';

    .section {
        background-color: transparent;
    }

    .custom-category-card{
        display: inline-block;
        column-break-inside: avoid;
        box-shadow: 1px 2px 10px 1px rgba(0, 0, 0, 0.25);
        border-radius: 0px 0px 0px 50px;

        @media (max-width: 576px) {
            width: 100%;
        }
    }

    .category-img {

        @media (max-width: 420px) {
            margin-right: 10px;
            min-width: 90px;
            height: 90px;
        }

        img{
            width: 70px;

            @media (max-width: 420px) {
                width: 60px;
            }
        }
    }

    .category-card-content__title {
        margin-right: 5px;
    }

    .card-columns{
        @media (max-width: 880px) {
            column-count: 2;
        }

        @media (max-width: 576px) {
            column-count: 1;
        }
    }

    .category-label{
        color: $primary;
        font-size: 35px;
        font-family: "Myriad", sans-serif;

        @media (max-width: 420px) {
            font-size: 25px;
        }

    }

    .people-container {
        padding-left: 100px;
        margin-left: -100px;

        @media (max-width: 991px){
            padding-left: 0;
            margin-left: 0px;
            min-width: 100%;
        }
        @media(max-width: 1250px){
            flex: 0 0 100%;
            max-width: 100%;
        }

        @media (min-width:1251px) and(max-width: 1500px){
            flex: 0 0 20%;
            max-width: 20%;
        }
    }

    .people {
        min-width: 730px;

        @media (min-width: 1251px) {
            position: absolute;
            top: 220px;
        }

        @media (max-width: 1880px) {
            min-width: 700px;
            top: 200px;
        }
        @media (max-width: 1800px) {
            min-width: 670px;
            top: 240px;
        }
        @media (max-width: 1750px) {
            min-width: 640px;
            top: 255px;
        }
        @media (max-width: 1700px) {
            min-width: 610px;
        }
        @media (max-width: 1650px) {
            min-width: 570px;
            top: 270px;

        }
        @media (max-width: 1600px) {
            min-width: 520px;
            top: 285px;
        }
        @media (max-width: 1450px) {
            min-width: 480px;
            top: 300px;
        }
        @media (max-width: 1350px) {
            min-width: 430px;
            top: 310px;

        }
        @media (max-width: 1250px) {
            min-width: auto;
        }
    }

    .col-container {
        @media(max-width: 1250px){
            flex: 0 0 100%;
            max-width: 100%;
        }

        @media (min-width: 1251px) and (max-width: 1500px){
            flex: 0 0 80%;
            max-width: 80%;
        }
    }

    body[dir="rtl"] {
        .people {
            transform: scaleX(-1);
        }

        .custom-category-card{
            border-radius: 0px 0px 50px 0px;  
        }
    }


</style>